import {TopicAreaViewModel, TopicViewModel} from "../components/viewModels"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "./appStore"
import {enableArrayExtensions} from "../libs/array-extensions"

enableArrayExtensions()

interface ThemeWorldState {
    selectedTopicAreas: Array<TopicAreaViewModel>
    enabledTopics: Array<TopicViewModel>
    selectedTopics: Array<TopicViewModel>
    scrollPosition: [number, number]
}

const initialState: ThemeWorldState = {
    selectedTopicAreas: [],
    enabledTopics: [],
    selectedTopics: [],
    scrollPosition: [0, 0]
}

export const themeWorldPageSlice = createSlice({
    name: 'themeWorld',
    initialState,
    reducers: {
        themeWorldResetAll: state => {
            state.selectedTopicAreas = []
            state.enabledTopics = []
            state.selectedTopics = []
            state.scrollPosition = [0, 0]
        },
        themeWorldSetSelectedTopicAreas: (state, action: PayloadAction<Array<TopicAreaViewModel>>) => {
            const topics = action.payload
                    .map(item => item.topics)
                    .reduce((a, b) => a.mergeDistinct(b)
                        , [])
                || []
            const topicNames = topics.map(item => item.topic)
            state.selectedTopicAreas = action.payload
            state.enabledTopics = topics
            state.selectedTopics = state.selectedTopics.filter(item => topicNames.includes(item.topic))
        },
        themeWorldSetSelectedTopics: (state, action: PayloadAction<Array<TopicViewModel>>) => {
            state.selectedTopics = action.payload
        },
        themeWorldSetScrollPosition: (state, action: PayloadAction<[number, number]>) => {
            state.scrollPosition = action.payload
        }
    }
})

export const {
    themeWorldSetSelectedTopicAreas,
    themeWorldResetAll,
    themeWorldSetSelectedTopics,
    themeWorldSetScrollPosition
} = themeWorldPageSlice.actions
export const themeWorldSelectedTopicAreas = (state: RootState) => state.themeWorld.selectedTopicAreas
export const themeWorldEnabledTopics = (state: RootState) => state.themeWorld.enabledTopics
export const themeWorldSelectedTopics = (state: RootState) => state.themeWorld.selectedTopics
export const themeWorldScrollPosition = (state: RootState) => state.themeWorld.scrollPosition
