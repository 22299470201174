module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"625fd6ebbedc878ca397a52d40a9a4ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"i18n","languages":["de","en"],"defaultLanguage":"de","i18nextOptions":{"keySeparator":"."}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"showCaptions":false,"quality":50,"withAvif":true,"withWebp":true,"loading":"lazy","decoding":"async","srcSetBreakpoints":[400,600,900,1200,1536]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false,"showCaptions":false,"quality":50,"withAvif":true,"withWebp":true,"loading":"lazy","decoding":"async","srcSetBreakpoints":[400,600,900,1200,1536],"markdownCaptions":false,"backgroundColor":"white","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
