/**
 * Merge two arrays into one, where in the resulting array all items are unique.
 *
 * @param a:Array<T>
 * @param b:Array<T>
 *
 * @return new array with merged unique items.
 */
export function mergeArraysDistinct<T>(a: Array<T>, b: Array<T>): Array<T> {
    const [setA, setB] = [new Set<T>(a), new Set<T>(b)]
    setB.forEach((item) => setA.add(item))
    return [...setA.values()]
}
