import {isNull, isUndefined} from "../predicates"
import {shuffledArray} from "./shuffledArray"
import {mergeArraysDistinct} from "./mergeArraysDistinct"

declare global {
    interface Array<T> {
        filterNonNull(): Array<NonNullable<T>>
        mapNonNull<R extends any>(callback: (item: T) => R | null | undefined): Array<R>
        shuffled(): Array<T>
        mergeDistinct(other: Array<T>): Array<T>
    }
}

function filterNonNull(this: Array<any>): Array<NonNullable<any>> {
    return this.filter((item) => !isNull(item) && !isUndefined(item))
}

function mapNonNull<T, R extends any>(this: Array<T>, callback: (item: T | null | undefined) => R | null | undefined): Array<R> {
    const result = [] as Array<R>
    for (const item of this) {
        const newItem = callback(item)
        if (newItem !== null && typeof newItem !== 'undefined') {
            result.push(newItem)
        }
    }
    return result
}

function shuffled<T>(this: Array<T>): Array<T> {
    return shuffledArray(this)
}

function mergeDistinct<T>(this: Array<T>, other: Array<T>): Array<T> {
    return mergeArraysDistinct(this, other)
}

export function enableArrayExtensions() {
    Array.prototype.filterNonNull = filterNonNull
    Array.prototype.mapNonNull = mapNonNull
    Array.prototype.shuffled = shuffled
    Array.prototype.mergeDistinct = mergeDistinct
}
