/**
 * Returns a new shuffled array.
 *
 * @param array
 */

export function shuffledArray<T>(array?: Array<T>): Array<T> {
    if (!array) {
        return []
    }

    if (!Array.isArray(array)) return []

    const clone = array.slice(0)
    shuffleFisherYates(clone)
    return clone
}

/**
 * Shuffle an array.
 *
 * @param array
 */
function shuffleFisherYates(array: Array<any>) {
    for (let index: number = array.length - 1; index > 0; index--) {
        const otherIndex: number = Math.floor(Math.random() * (index + 1))
        const [item, otherItem] = [array[index], array[otherIndex]]
        array[otherIndex] = item
        array[index] = otherItem
    }
}
