import {configureStore} from "@reduxjs/toolkit"
import {themeWorldPageSlice} from "./themeWorldPageSlice"

export const createStore = () => {
    return configureStore({
        reducer: {
            themeWorld: themeWorldPageSlice.reducer
        },
    })
}

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
