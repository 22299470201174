export const isNull = <T>(value: T | null | undefined): boolean => value === null
export const isUndefined = <T>(value: T | null | undefined): boolean => typeof value === 'undefined'
export const equals = <T>(expected: T, value: T | null | undefined): boolean => equalsDeeply(expected, value)
export const isSame = <T>(expected: T, value: T | null | undefined): boolean => expected === value
export const exists = <T>(value: T | null | undefined): value is T => !isNull(value) && !isUndefined(value)

export function equalsDeeply(a: any | null | undefined, b: any | null | undefined): boolean {
    if (a === null && b === null) return true

    const typeOfA = typeof a

    switch (typeOfA) {
        case 'undefined':
            return typeof b === 'undefined'
        case 'boolean':
            return a === b
        case 'number':
            return a == 0 + b
        case 'string':
            return a == '' + b

        default:
            return objectEquals(a, b)
    }
}

export function objectEquals(a: any | null | undefined, b: any | null | undefined): boolean {
    if (typeof a === 'undefined' && typeof b === 'undefined') return true
    if (typeof a !== 'object') return false
    if (a === null && b === null) return true
    if (Array.isArray(a)) return arrayEquals(a, b)
    if (typeof b !== 'object' || b === null) return false

    return arrayEquals(Object.keys(a).sort(), Object.keys(b).sort())
        && Object.keys(a)
            .map((key) => equalsDeeply(a[key], b[key])).reduce((pre, current) => pre && current)
}

export function arrayEquals(a: Array<any>, b: Object | null | undefined): boolean {
    if (typeof a !== 'object' || a === null) return false
    if (typeof b !== 'object' || b === null) return false

    return Array.isArray(a) && Array.isArray(b)
        && a.length === b.length
        && a.every((aItem, index) => aItem == b[index])
}
