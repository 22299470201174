import {CSSProperties} from "theme-ui";

export const ocosoColors = {
    red: "#E22D36",
    darkred: "#A32026",
    yellow: "#E2A517",
    darkyellow: "#916F1D",
    green: "#78BC24",
    darkgreen: "#53821A",
    turquoise: "#33AA4D",
    darkturquoise: "#1A802F",
    cyan: "#169C93",
    darkcyan: "#00706A",
    blue: "#3D6FEB",
    darkblue: "#234EB3",
    purple: "#8F33FF",
    repeccapurple: "#6423B3",
    pink: "#BF25A0",
    darkpink: "#8A1B73",
    deeppink: "#8A1B73",
    gray: "#ccc",
    darkgray: "#999",
    grey: "#ccc",
    darkgrey: "#999",
    black: "#000",
    white: "#fff"
}

export type ColorDefinition = typeof ocosoColors
