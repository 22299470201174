import React, {PropsWithChildren} from "react"
import {AuthProvider} from "react-oidc-context"
import {User} from "oidc-client-ts"
import configuration from "../../static/configuration.json"

export function currentWindowHref(): string {
    if (typeof window === 'undefined') return ''
    return window.location.href
}

export function currentWindowOrigin(): string {
    if (typeof window === 'undefined') return ''
    return window.location.origin
}


export function AppAuthProvider(props: PropsWithChildren<any>): JSX.Element {
    const onSigninCallback = (_user: User | void): void => {
        console.log("ON LOGIN", _user)
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }

    return (
        <AuthProvider authority={configuration.security.oauth2["issuer-uri"]}
                      client_id={configuration.security.oauth2["client-id"]}
                      redirect_uri={currentWindowOrigin()}
                      onSigninCallback={onSigninCallback}
        >
            {props.children}
        </AuthProvider>
    )

    //
    // return (
    //     !!configuration) ? (
    //         <AuthProvider
    //             key="withConfiguration"
    //             redirect_uri={window.location.origin}
    //             authority={configuration.security?.oauth2?.["issuer-uri"] ?? ''}
    //             client_id={configuration.security?.oauth2?.["client-id"] ?? ''}
    //             onSigninCallback={onSigninCallback}
    //         >
    //             {props.children}
    //         </AuthProvider>
    //     )
    //     : (
    //         <AuthProvider key="withoutConfiguration" {...{
    //             authority: "",
    //             client_id: "",
    //             redirect_uri: "",
    //             onSigninCallback: onSigninCallback
    //         }}>
    //             {props.children}
    //         </AuthProvider>
    //     )
}
