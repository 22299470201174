import React from "react";
import "./static/common.css"
import type {GatsbyBrowser} from "gatsby";
import {Provider} from "react-redux";
import {store} from "./src/redux";
import {AppAuthProvider} from "./src/security/AppAuthProvider";
import {ThemeProvider} from "theme-ui";
import ocosoTheme from "./src/styles";
import components from "./src/styles/mdxComponents";
import {MDXProvider, useMDXComponents} from "@mdx-js/react";
import {useThemedStylesWithMdx} from "@theme-ui/mdx";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({element}) => (
    <Provider store={store}>
        <AppAuthProvider>
            {element}
        </AppAuthProvider>
    </Provider>
)

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element}) => {
    return (
        <ThemeProvider theme={ocosoTheme}>
            <MDXProvider components={useThemedStylesWithMdx(useMDXComponents(components))}>
                {element}
            </MDXProvider>
        </ThemeProvider>
    )
}
