import {Theme, ThemeUIContextValue, useThemeUI} from "theme-ui";
import {ocosoColors} from "./ocosoColors";
import {ocosoGradients} from "./ocosoGradients";
import {alpha} from "@theme-ui/color";


const StandardFont = 'AvenirRegular, sans'
const BoldFont = "AvenirBold, sans"
const ThinFont = "AvenirUltraLight, sans"

const defaultSizes = {
    xs: "400px",
    sm: "600px",
    md: "900px",
    lg: "1200px",
    xl: "1536px"
} as const

const defaultSpaces = {
    xs: "1rem",
    sm: "2rem",
    md: "3rem",
    lg: "5rem",
    xl: "8rem"
}

export const breakpoints = [defaultSizes.xs, defaultSizes.sm, defaultSizes.md, defaultSizes.lg, defaultSizes.xl]
export const fontSizes = ['16px', '125%', '150%', '150%', '150%']

const makeTheme = <T extends Theme>(t: T) => t

const ocosoTheme = makeTheme({
    config: {
        //initialColorModeName: 'light',
        //useColorSchemeMediaQuery: 'system',
        //useRootStyles: false
        useCustomProperties: true
    },
    breakpoints: breakpoints,
    colors: {
        background: ocosoColors.white,
        primary: ocosoColors.deeppink,
        secondary: ocosoColors.yellow,
        accent: ocosoColors.darkcyan,
        text: ocosoColors.black,
        ...ocosoColors,
        links: {
            primary: ocosoColors.deeppink,
            active: ocosoColors.darkcyan
        },
        button: {
            primary: ocosoColors.deeppink,
            active: ocosoColors.darkcyan,
            text: ocosoColors.white
        },
        footer: {
            primary: ocosoColors.white,
            text: ocosoColors.white,
            background: "transparent"
        },
        gradients: {
            ...ocosoGradients
        },
    },
    fonts: {
        body: StandardFont,
        heading: BoldFont,
        title: ThinFont,
        bold: BoldFont
    },
    fontWeights: {
        body: 200,
        title: 200,
        heading: 200
    },
    fontSizes: fontSizes,
    lineHeights: {
        body: "143%",
        title: 1
    },
    sizes: {
        ...defaultSizes,
        appbar: {
            width: "100%",
            minHeight: "3rem",
            height: "3rem"
        },
        drawer: '80%'
    },
    space: {
        ...defaultSpaces,
        withAppbar: {
            paddingTop: "3rem"
        }
    },
    transitions: {
        drawer: "all 0.5s ease-out"
    },
    zIndices: {
        appbar: 1100,
        drawer: 1200,
    },
    buttons: {
        design: {
            "--text-color": "white",
            variant: "layout.designButton",
            color: "primary",
            textColor: "white",
            activeColor: "accent"
        },
        primary: {
            color: "yellow"
        },
        teaser: {
            variant: "layout.designButton",
            color: "black",
            textColor: "white",
            activeColor: "accent"
        }
    },
    layout: {
        appbar: {
            position: "fixed",
            backgroundColor: "white",
            color: "primary",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            zIndex: "appbar",
            width: "appbar.width",
            minHeight: "appbar.minHeight",
            height: "appbar.height",

            left: 0,
            right: 0,
            top: 0,
        },
        drawer: {
            root: {
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: "drawer",
                backgroundColor: "transparent",
                visibility: "hidden",
                transition: "drawer",
                "&.opened": {
                    visibility: "visible",
                },
                "&.visible": {
                    backgroundColor: alpha("grey", 0.8),
                }
            },
            slice: {
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                top: 0,
                bottom: 0,
                right: "-101vw",
                width: "drawer",
                maxWidth: "min(30rem, 90vw)",
                visibility: "visible",
                opacity: "95%",
                backgroundColor: "white",
                transition: "drawer",
                paddingX: "xs",
                paddingY: "xs",
                "&.opened": {
                    right: 0
                }
            },
        },

        //
        // Layout for Footer
        //
        footer: {
            display: "grid",
            color: "footer.primary",
            //backgroundColor: "footer.background",
            contents: {
                //paddingY: "sm",
                gridArea: "1/1",
                position: "relative",
                backgroundColor: "transparent",
                top: 0,
                left: 0,
                paddingY: "sm",
                alignSelf: "center",
                justifySelf: "center"
            },
            backgroundImage: {
                height: "inherit",
                maxHeight: "inherit",
                gridArea: "1/1"
            },
            // style the inner image
            image: {
                height: "inherit",
                maxHeight: "inherit"
            }
        },

        designButton: {
            maxWidth: "30rem",
            minWidth: "10rem",
            height: "3rem",
            width: "100%"
        },

        //
        // Layout for the main (page) segment
        //
        main: {
            paddingTop: "3rem",
            paddingX: "xs"
        },

        //
        // OCOSO specific components
        //
        ocoso: {
            footer: {
                logo: {
                    variant: "layout.adaptHeight",
                    height: "3rem",
                },
                contents: {
                    alignSelf: "center",
                    display: "grid",
                    justifyItems: "start",
                    alignItems: "start",
                    maxWidth: "max-content",
                    rowGap: "2rem",
                    "& a": {
                        height: "inherit",
                        color: "inherit",
                        textDecoration: "none"
                    },
                    "& ul": {
                        height: "inherit",
                        listStyleType: "none",
                        marginInline: 0,
                        marginBlock: 0,
                        paddingInline: 0
                    },
                    ".footer-orientation-portrait > &": {
                        gridTemplateColumns: "auto 1fr",
                        gridTemplateAreas: `
                            "none1 social"
                            "twirl logo"
                            "none2 nav"
                            "none2 navStandard"
                            "none2 copy"
                        `
                    },
                    ".footer-orientation-landscape > &": {
                        gridTemplateColumns: "auto 1fr auto",
                        gridTemplateAreas: `
                            "none1 social social"
                            "twirl logo none3"
                            "none2 nav navStandard"
                            "none2 nav copy"
                        `
                    },
                    ".ocoso-footer-social": {
                        gridArea: "social",
                        height: "1.5rem"
                    },
                    ".ocoso-footer-twirl": {
                        variant: "layout.ocoso.footer.logo",
                        gridArea: "twirl",
                        justifySelf: "end"
                    },
                    ".ocoso-footer-logo": {
                        variant: "layout.ocoso.footer.logo",
                        gridArea: "logo"
                    },
                    ".ocoso-footer-nav": {
                        gridArea: "nav"
                    },
                    ".ocoso-footer-standard-nav": {
                        gridArea: "navStandard"
                    },
                    ".ocoso-footer-copyright": {
                        gridArea: "copy",
                        fontSize: "50%"
                    }
                }
            }
        },

        page: {
            display: "flex",
            flexDirection: "column",
            rowGap: "xl"
        },

        responsiveSvg: {
            color: "inherit",
            fill: "inherit",
            height: "inherit",
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: "max-content",
            maxWidth: "fit-content"
        },

        sidebar: {
            toolbar: {
                variant: "layout.toolbar",
                height: "1.5rem",
                justifyContent: "space-between",
                div: {
                    height: "inherit",
                },
            },
            content: {
                variant: "text.title",
                fontSize: "2rem",
                paddingInline: "xs",
                paddingBlock: "sm",
                flexFlow: "column nowrap",
                rowGap: "sm",
                height: "100%",

                "& > li": {
                    listStyle: "none"
                }
            },
            footer: {
                color: "inherit",
                height: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "sm",
                "&>*": {
                    height: "inherit",
                    color: "inherit"
                }
            }
        },
        teaser: {
            flexDirection: "column",
            width: "100%",
            contents: {
                display: "block",
                marginTop: "md",
                flexDirection: "column",
                columnWidth: "15rem",
                columnCount: "auto",
                maxWidth: "40rem",
                "&>p:first-of-type": {
                    marginTop: 0
                },
                "&>p": {
                    marginTop: "sm"
                }
            },
            footer: {
                marginTop: "md"
            },
            header: {},
            image: {
                flexDirection: "row",
                justifyContent: "stretch",
                width: "100%",
                marginTop: "md"
            }
        },
        teaserBox: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            rowGap: "lg"
        },
        toolbar: {
            position: "relative",
            paddingX: "xs",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "inherit",
        },
        adaptHeight: {
            height: "inherit",
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: "max-content",
            maxWidth: "fit-content"
        },
    },
    links: {
        default: {
            color: "links.primary",
            "&:hover": {
                color: "links.active"
            },
            "&:active": {
                color: "links.active",
                fontWeight: "bold"
            }
        }
    },
    text: {
        bold: {
            fontFamily: "bold",
            fontWeight: "body",
            lineHeight: "body"
        },
        standard: {
            fontFamily: "body",
            fontWeight: "body",
            lineHeight: "body"
        },
        footer: {
            fontFamily: "body",
            fontWeight: "body",
            color: "background"
        },
        title: {
            fontFamily: "title",
            fontWeight: "title",
            lineHeight: "title",
            fontSize: "3.5rem",
            backgroundImage: ocosoGradients.default,
            backgroundSize: "100%",
            paddingTop: "sm",
            paddingBottom: "lg",
            hyphens: "auto",
            border: 0,
            WebkitBackgroundSize: "100%",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            WebkitBoxDecorationBreak: "clone",
        },
        h2: {
            fontFamily: "heading",
            fontWeight: "heading",
            fontSize: "1.3rem",
            marginBlockStart: "3rem",
            marginBlockEnd: "2rem",
            marginInlineStart: 0,
            marginInlineEnd: 0,
            border: 0
        },
        h3: {
            fontFamily: "heading",
            fontWeight: "heading",
            fontSize: "1rem",
            lineHeight: "150%",
            marginBlockStart: "2rem",
            marginBlockEnd: "1.5rem",
            marginInlineStart: 0,
            marginInlineEnd: 0
        },
        p: {
            fontFamily: "body",
            fontWeight: "body",
            marginBlockStart: "2rem"
        }
    },
    styles: {
        root: {
            fontFamily: "body",
            lineHeight: "body",
            fontWeight: "body",
            fontSize: fontSizes,
            boxSizing: "border-box",
            ul: {
                listStyleType: "'\\23bc  '",
            },
        },
        a: {
            variant: "links.default"
        },
        h1: {
            variant: "text.title",
            marginBlockStart: "md",
            marginBlockEnd: "lg",
            padding: 0
        },
        h2: {
            variant: "text.h2"
        },
        h3: {
            variant: "text.h3"
        },
        p: {
            variant: "text.standard",
            maxWidth: "45rem",
            border: 0,
            paddingLeft: "0rem"
        },
        strong: {
            variant: "text.bold"
        },
    },
})

export type OcosoTheme = typeof ocosoTheme

interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
    theme: OcosoTheme
}

export const useTheme = (useThemeUI as unknown) as () => ExactContextValue

export default ocosoTheme
