import {ocosoColors} from "./ocosoColors";

const gradientNames = [
    "default",
    "reverse"
] as const

export type GradientNames = typeof gradientNames
export type GradientName = GradientNames[number]

export type GradientDefinition = Record<GradientName, string>

export const ocosoGradients: GradientDefinition = {
    default: `linear-gradient(90deg, ${ocosoColors.yellow}, ${ocosoColors.red}, ${ocosoColors.repeccapurple})`,
    reverse: `linear-gradient(90deg, ${ocosoColors.repeccapurple}, ${ocosoColors.red}, ${ocosoColors.yellow})`,
}
